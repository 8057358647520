import React from 'react'
import AllApplicantsPage from './components/AllApplicantPage'

const AllApplicates: React.FC = () => {
  return (
    <div>
        <AllApplicantsPage />
    </div>
  )
}

export default AllApplicates