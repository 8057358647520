import React from 'react'
import AppliedJobsPage from './components/AppliedJobsPage'

const AppliedJobs = () => {
  return (
    <div>
      <AppliedJobsPage />
    </div>
  )
}

export default AppliedJobs